<template>
    <div v-if="loading">
       <LoadingState></LoadingState>
     </div>
     <div v-else
     class="bg-gradient-to-tr from-blue-600 via-sky-600 to-indigo-500 h-64 pt-[56px] w-full flex items-center dark:bg-gradient-to-tr dark:from-gray-900 dark:via-slate-800 dark:to-gray-900"
   >
     <div class="container mx-auto">
       <div
         class="2xl:border-l-4 xl:border-l-4 lg:border-l-4 md:border-l-4 border-white dark:border-lime-600 2xl:text-start xl:text-start md:text-start text-center"
       >
         <h1
           class="font-bold text-4xl text-white mb-2 indent-2 dark:text-lime-500"
         >
           {{ $t("navbar.active-tariffs") }}
         </h1>
       </div>
  
       <a
         onclick="window.history.back()"
         class="text-white dark:text-lime-500 2xl:hidden xl:hidden lg:hidden md:hidden inline-flex items-center font-medium hover:text-gray-900 dark:hover:text-white cursor-pointer justify-center w-full"
       >
         <svg
           xmlns="http://www.w3.org/2000/svg"
           fill="none"
           viewBox="0 0 24 24"
           stroke-width="1.5"
           stroke="currentColor"
           class="w-4 h-4 mr-2"
         >
           <path
             stroke-linecap="round"
             stroke-linejoin="round"
             d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"
           />
         </svg>
         <span>{{ $t("table.go-back") }}</span>
       </a>
  
       <nav
         aria-label="Breadcrumb"
         class="2xl:block xl:block lg:block md:block hidden"
       >
         <ol class="flex space-x-1 md:space-x-3 text-white dark:text-lime-500">
           <li
             class="flex items-center hover:text-gray-900 dark:hover:text-white cursor-pointer hover:color-gray-900"
           >
             <a
               @click="this.$router.push('/')"
               class="inline-flex items-center font-medium"
             >
               <svg
                 class="w-4 h-4 mr-2"
                 fill="white"
                 viewBox="0 0 20 20"
                 xmlns="http://www.w3.org/2000/svg"
               >
                 <path
                   d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
                 ></path>
               </svg>
               <span class="hidden sm:block">{{
                 $t("ceo-message.main-screen")
               }}</span>
             </a>
           </li>
           <li aria-current="page">
             <div class="flex items-center">
               <svg
                 class="w-6 h-6 text-gray-400"
                 fill="white"
                 viewBox="0 0 20 20"
                 xmlns="http://www.w3.org/2000/svg"
               >
                 <path
                   fill-rule="evenodd"
                   d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                   clip-rule="evenodd"
                 ></path>
               </svg>
               <span class="ml-1 font-medium md:ml-2 select-none">{{
                 $t("navbar.active-tariffs")
               }}</span>
             </div>
           </li>
         </ol>
       </nav>
     </div>
   </div>
  
   <div
     class="container mx-auto border dark:border-lime-600 m-5 rounded-xl shadow pb-5 bg-slate-50/25 dark:bg-gray-777"
   >
     <div class="container mx-auto">
       <div class="flex justify-center items-center space-x-20">
         <div
           class="dark:text-lime-400 2xl:px-20 xl:px-10 lg:px-10 md:px-10 sm:px-5 px-5 text-justify"
         >
           
         </div>
       </div>
     </div>
     <div class="dark:bg-gray-900 dark:text-lime-400">
       <div class="container mx-auto dark:py-10">
         <div class="float-right w-1/12 my-1 mx-3">
                 <button v-show="this.$i18n.locale == 'ru'"  @click=Download(this.$i18n.locale)><img src="../assets/img/pdf.png" width="50" alt="pdf" height="50"></button>
                 <button v-show="this.$i18n.locale == 'uz'"  @click=Download(this.$i18n.locale)><img src="../assets/img/pdf.png" width="50" alt="pdf" height="50"></button>
                 <button v-show="this.$i18n.locale == 'en'"  @click=Download(this.$i18n.locale)><img src="../assets/img/pdf.png" width="50" alt="pdf" height="50"></button>
         </div>
         <div class="text-justify 2xl:px-10 xl:px-5 lg:px-5 md:px-2 sm:px-5 py-5 ml-5 mr-5 2xl:mt-5">
            <!-- <div class="2xl:text-center xl:text-justify lg:text-justify md:text-justify sm:text-justify 2xl:mx-5 2xl:block">        
            <iframe :src="pdfsrc" type="application/pdf" class="2xl:h-table w-full sm:h-pdfView"></iframe>
            </div> -->
            <div class="2xl:text-center xl:text-justify lg:text-justify md:text-justify sm:text-justify 2xl:mx-5 2xl:block">        
                <object :data="pdfsrc" type="application/pdf" frameborder="0" width="100%" height="600px" >
                    <embed :src="pdfsrc" class="w-full 2xl:h-table xl:h-table md:h-52 sm:h-52"/>
            </object>
            </div>
         </div>
       </div>
  
     </div>
   </div>
  </template>
  <script>
  import LoadingState from '@/components/LoadingState.vue';
  import axios from 'axios'
  export default {
   components:{
     LoadingState
   },
   data: () => {
     return {
       pdfsrc: null,
       loading:true
     };
   },
   methods: {
     async getPDFPath() {
       this.pdfsrc = null;
       let uzId = 2220;
       let ruId = 2219;
       let enId = ruId;
       this.loading = true;
  
       try {
         let locale = this.$i18n.locale;
         let response;
  
         if (locale === 'ru') {
           response = await axios.get('/api/Website/GetActiveTarif/' + ruId);
         } else if (locale === 'uz') {
           response = await axios.get('/api/Website/GetActiveTarif/' + uzId);
         } else if (locale === 'en') {
           response = await axios.get('/api/Website/GetActiveTarif/' + enId);
         }
  
         if (response && response.data) {
           let pdfString = response.data;
           this.pdfsrc = "data:application/pdf;base64," + pdfString;
         }
       } catch (error) {
         console.error(error);
       } finally {
         this.loading = false; 
       }
     },
     async Download(locale) {
      let uzId = 2220;
      let ruId = 2219;
      //let ruId = 2019;
      let enId = ruId;
      let path = null;
      try {
            switch(locale){
              case 'ru':
              path = "/api/Website/GetDownloadPdfById/" + ruId;
              break;
              case 'uz':
              path = "/api/Website/GetDownloadPdfById/" + uzId;
              break;
              case 'en':
              path = "/api/Website/GetDownloadPdfById/" + enId;
              break;
            }
          
          let response = await this.axios.get(path, {
              responseType: 'blob' // Set responseType to 'blob' to receive binary data
          });
  
          let blob = new Blob([response.data], { type: response.headers['content-type'] });
  
          // Create a temporary URL for the blob
          let url = window.URL.createObjectURL(blob);
  
          // Create a link element to trigger the download
          let link = document.createElement('a');
          link.href = url;
  
          // Simulate a click on the link to trigger the download
          link.click();
  
          // Clean up by revoking the object URL
          window.URL.revokeObjectURL(url);
      } catch (error) {
          console.log(error);
      } finally {
          this.loading = false;
      }
    },
   },
   watch: {
     '$i18n.locale': 'getPDFPath'
   },
   mounted() {
     this.getPDFPath();
     this.visible = true;
   }
  };
  </script>