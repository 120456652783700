<template>
    <div class="bg-gradient-to-tr from-blue-600 via-sky-600 to-indigo-500 h-64 pt-[56px] w-full flex items-center dark:bg-gradient-to-tr dark:from-gray-900 dark:via-slate-800 dark:to-gray-900">
      <div class="container mx-auto">
        <div class="2xl:border-l-4 xl:border-l-4 lg:border-l-4 md:border-l-4 border-white dark:border-lime-600 2xl:text-start xl:text-start md:text-start text-center">
          <h1 class="font-bold text-4xl text-white mb-2 indent-2 dark:text-lime-500">{{ $t('home-page.government-bonds') }}</h1>
        </div>
  
        <a onclick="window.history.back()" class="text-white dark:text-lime-500 2xl:hidden xl:hidden lg:hidden md:hidden inline-flex items-center font-medium hover:text-gray-900 dark:hover:text-white cursor-pointer justify-center w-full">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 mr-2">
            <path stroke-linecap="round" stroke-linejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3" />
          </svg>
          <span>{{ $t('table.go-back') }}</span>
        </a>
  
        <nav aria-label="Breadcrumb" class="2xl:block xl:block lg:block md:block hidden">
          <ol class="flex space-x-1 md:space-x-3 text-white dark:text-lime-500">
            <li class="flex items-center hover:text-gray-900 dark:hover:text-white cursor-pointer hover:color-gray-900">
              <a @click="this.$router.push('/')" class="inline-flex items-center font-medium">
                <svg class="w-4 h-4 mr-2" fill="white" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
                </svg>
                <span class="hidden sm:block">{{ $t('ceo-message.main-screen') }}</span>
              </a>
            </li>
            <li aria-current="page">
              <div class="flex items-center">
                <svg class="w-6 h-6 text-gray-400" fill="white" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clip-rule="evenodd"></path>
                </svg>
                <span class="ml-1 font-medium md:ml-2 select-none">{{ $t('home-page.government-bonds') }}</span>
              </div>
            </li>
          </ol>
        </nav>
      </div>
    </div>
  
    <div class="container h-table mx-auto mt-navbar py-10 mb-10">
      <div class="flex justify-between">
        <h2 class="mb-4 text-xl font-extrabold leading-none tracking-tight text-blue-500 dark:text-lime-400 text-center">{{$t('home-page.government-bonds')}}</h2>
        <button v-on:click="onBtnExport()" class="bg-blue-100 hover:bg-blue-300 text-blue-800 dark:text-green-800 text-xs font-medium px-2.5 py-0.5 rounded dark:bg-lime-400 border border-blue-400 dark:border-lime-900 mb-2 cursor-progress">{{ $t('others.csv') }}</button>
      </div>
      <AgGridVue
          style="width: 100%; height: 100%;"
          :class="themes"
          :columnDefs="datas"
          @grid-ready="onGridReady"
          :defaultColDef="defaultColDef"
          :localeText="localeText"
          :enableCellTextSelection="true"
          :paginationAutoPageSize="true"
          :pagination="true"
          :animateRows="true"
          :rowData="rowData"></AgGridVue>
    </div>
  </template>
  
  <script>
  import 'ag-grid-community/styles/ag-grid.css';
  import 'ag-grid-community/styles/ag-theme-alpine.css';
  import {AgGridVue} from "ag-grid-vue3";
  export default {
    components: {AgGridVue},
    data(){
      return {
        gridApi: null,
        columnApi: null,
        defaultColDef: {
          sortable: true,
          resizable: true,
          filter: true,
          suppressMenu: false,
          flex: 1,
        },
        autoGroupColumnDef: null,
        rowData: null,
        localeText: null,
        currentTheme: null,
      };
    },
    computed: {
      themes () {
        if(localStorage.getItem('theme') != 'dark'){
          return 'ag-theme-alpine';
        }
        else{
          return 'ag-theme-alpine-dark';
        }
      },
      datas() {
        return [
        {
            headerName: this.$t('others.issuer'),
            field: 'issuer',
            maxWidth: 350,
            filterParams: { buttons: ['clear', 'reset', 'apply'], },
         }, 
         {
            headerName: this.$t('others.reg-number'),
            field: 'regNumber',
            maxWidth: 135,
            filterParams: { buttons: ['clear', 'reset', 'apply'], },
          }, 
          {
            headerName: this.$t('others.quantity'),
            field: 'quantity',
            maxWidth: 125,
            filterParams: { buttons: ['clear', 'reset', 'apply'], },
          },
          {
            headerName: this.$t('others.denomination'),
            field: 'denomination',
            maxWidth: 100,
            filterParams: { buttons: ['clear', 'reset', 'apply'], },
          },
          {
            headerName: this.$t('others.currency'),
            field: 'currency',
            maxWidth: 100,
            filterParams: { buttons: ['clear', 'reset', 'apply'], },
          }, 
          {
            headerName: this.$t('others.issue-date'),
            field: 'issueDate',
            maxWidth: 110,
            filterParams: { buttons: ['clear', 'reset', 'apply'], },
          },
          {
            headerName: this.$t('others.maturity-date'),
            field: 'maturityDate',
            maxWidth: 110,
            filterParams: { buttons: ['clear', 'reset', 'apply'], },
          },
          {
            headerName: this.$t('others.coupon-rate'),
            field: 'couponRate',
            maxWidth: 110,
            filterParams: { buttons: ['clear', 'reset', 'apply'], },
          }, 
         {
            headerName: 'ISIN',
            field: 'isin',
            maxWidth: 140,
            filterParams: { buttons: ['clear', 'reset', 'apply'], },
          },
          {
            headerName: 'CFI',
            field: 'cfi',
            maxWidth: 90,
            filterParams: { buttons: ['clear', 'reset', 'apply'] },
          },
          {
            headerName: 'FISN',
            field: 'fisn',
            maxWidth: 370,
            filterParams: { buttons: ['clear', 'reset', 'apply'] },
          },
        ]
      }
    },
    methods: {
      onBtnExport() {
        this.gridApi.exportDataAsCsv();
      },
      onGridReady(params) {
        this.gridApi = params.api;
  
        const updateData = (data) => params.api.setRowData(data);
  
        fetch('https://web.uzcsd.uz/api/Security/GetAllGovBonds')
            .then((resp) => resp.json())
            .then((data) => updateData(data));
      },
    },
  }
  </script>
  
  <style>
  .rag-red {
    color: coral;
  }
  .rag-green {
    color: limegreen;
  }
  .ag-theme-alpine .ag-header-cell {
    color: #3F83F8;
    font-size: 14px;
  }
  </style>